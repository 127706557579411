import { Navigate, useRoutes } from "react-router-dom";
// auth
// import AuthGuard from "../auth/AuthGuard";
// import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  LoginPage,
  HomePage,
  ProjectsPage,
  Page404,
  PasswordRecoveryPage,
  FormsPage,
  RewardsPage,
  EarningsPage,
  PresentationsPage,
  SettingsPage,
  AdminPage,
  CustomersPage,
  TeamPage,
  CalendarPage,
} from "./elements";
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "password-recovery",
          element: (
            <GuestGuard>
              <PasswordRecoveryPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <HomePage /> },
        { path: "projects", element: <ProjectsPage /> },
        { path: "sales", element: <FormsPage /> },
        { path: "customers", element: <CustomersPage /> },
        { path: "team", element: <TeamPage /> },
        { path: "rewards", element: <RewardsPage /> },
        { path: "earnings", element: <EarningsPage /> },
        { path: "presentations", element: <PresentationsPage /> },
        { path: "calendar", element: <CalendarPage /> },
        { path: "settings", element: <SettingsPage /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminPage />,
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
