/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useMemo,
  useState,
  useContext,
  useReducer,
} from "react";
import { CustomersTableRow } from "../pages/customers/CustomersTable";

type BitrixContextType = {
  deal: CustomersTableRow | null;
  dealList: CustomersTableRow[];
  setDealList: React.Dispatch<React.SetStateAction<CustomersTableRow[]>>;
  setDeal: React.Dispatch<React.SetStateAction<CustomersTableRow | null>>;
  loadingTrigger: boolean;
  triggerLoading: React.DispatchWithoutAction;
};

export const BitrixContext = createContext<BitrixContextType | null>(null);

// ----------------------------------------------------------------------

type BitrixProviderProps = {
  children: React.ReactNode;
};

export function BitrixProvider({ children }: BitrixProviderProps) {
  const [dealList, setDealList] = useState<CustomersTableRow[]>([]);
  const [deal, setDeal] = useState<CustomersTableRow | null>(null);
  const [loadingTrigger, triggerLoading] = useReducer((state) => !state, false);

  const memoizedValue = useMemo(
    () => ({
      dealList,
      deal,
      setDealList,
      setDeal,
      loadingTrigger,
      triggerLoading,
    }),
    [dealList, deal, setDealList, setDeal, loadingTrigger, triggerLoading],
  );

  return (
    <BitrixContext.Provider value={memoizedValue}>
      {children}
    </BitrixContext.Provider>
  );
}

export const useBitrixContext = () => {
  const context = useContext(BitrixContext);

  if (!context)
    throw new Error(
      "useBitrixContext context must be use inside BitrixProvider",
    );

  return context;
};
